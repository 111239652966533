import React, { useEffect, useState } from 'react'
import axios from 'axios'
import useWindowDimensions from './WindowDimensions'
import { Link } from 'react-router-dom'

function Awards(props) {

    useEffect(() => {
        getAwards()
    }, [])

    const [awards, setAwards] = useState([])
    const { height, width } = useWindowDimensions()

    const getAwards = () => {
        axios.get(`${props.api}/api/content/items/awards`, {
            headers: {
                "Content-Type": "application/json",
                'api-key': props.acctok,
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                setAwards(res.data)               
            })
            .catch(error => {
                console.log(error)
            })
    }

    const savedAwards = awards.slice(0, 5).map(award => (
        <div key={award._id} className='home-award-wrapper'>
            <img src={`${props.api}/storage/uploads${award.image.path}`} className='home-award-pic' />
        </div>
    ))

    const savedAwardsMobile = awards.slice(0, 3).map(award => (
        <div key={award._id} className='home-award-wrapper'>
            <img src={`${props.api}/storage/uploads${award.image.path}`} className='home-award-pic' />
        </div>
    ))

    return (
        <div className='awards-wrapper' id='awards'>
            <div className='container-fluid awards-body'>
                <h1>AWARDS</h1>
                <div className='home-awards-wrapper'>
                    {width > 768 ? savedAwards : savedAwardsMobile}
                </div>
                <div className='text-center mt-4'>
                    <Link to='/awards'> <button type='button' type='submit' className='btn btn-contact-message'>SEE ALL</button> </Link>
                </div>
            </div>
        </div>
    )
}

export default Awards