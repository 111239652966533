import axios from 'axios'
import React, { useEffect, useState } from 'react'
import panelpic from './img/panel1.jpg'

function HomePanel(props) {

    useEffect(() => {
        getServices()
    }, [])

    const [services, setServices] = useState([])

    const getServices = () => {

        axios.get(`${props.api}/api/content/items/services`, {
            headers: {
                "Content-Type": "application/json",
                'api-key': props.acctok,
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                setServices(res.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const panels = services.map((service, i) => (
        <div key={service._id} className='col-md-4 col-12 panel' style={{ backgroundImage: `url(${props.api}/storage/uploads${service.serviceimg.path})` }}>
            <div className='panel-number-wrappper'>
                <span className='panel-number'>0{i + 1}</span>
                <h1 className='top-h1'>{service.servicename.split(' ')[0]}</h1>
                <h1 className='bottom-h1'>{service.servicename.split(' ')[1]}</h1>
                <ul>
                    {service.services.map((x, i) => (
                        <li key={i}>&#8226; &nbsp; {x}</li>
                    ))}
                </ul>
            </div>
        </div>
    ))

    return (
        <div className='main-wrapper'>
            <div className='main-panel-wrapper container-fluid row no-gutters' >
                {panels}
            </div>
        </div>
    )
}

export default HomePanel