import React from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
// import TextField from '@mui/material/TextField'
import { TextField } from '@material-ui/core'
import * as Yup from 'yup'
import { useState } from 'react'

function WorkWithUs(props) {

    const validationSchema = Yup.object({
        name: Yup.string().required('Please enter your name'),
        email: Yup.string().email('Please enter a valid email address').required('Please enter your email address'),
        message: Yup.string().required('Please type a message for us'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, onSubmitProps) => {            
            setSubmitted('success')
            submittedMessage()
            axios.post(`${props.api}/api/content/item/contact`, {
                headers: {
                    "Content-Type": "application/json",
                    'api-key': props.acctok,
                    'Cache-Control': 'no-cache',
                },
                data: {
                   ...values
                }                
            })
                .then(res => {                   
                    if (res.status == 200) {
                        setSubmitted('success')
                        submittedMessage()
                    }
                })
                .catch(err => {
                    setSubmitted('failed')
                    submittedMessage()
                    console.log(err);
                })
            onSubmitProps.resetForm()

            // axios.post(`http://deecms.live/emailer/messagepost.php`, data)
            //     .then(res => {
            //         // console.log(res.data)
            //     })
            //     .catch(err => {
            //         console.log(err);
            //     }) 
        }
    })

    const [submitted, setSubmitted] = useState(null)

    const submittedMessage = () => {
        setTimeout(() => {
            setSubmitted(null)
        }, 4000)
    }

    return (
        <div className={`contact container-fluid ${props.css}`}>
            <h1>WORK WITH US</h1>
            <div className='contact-form'>
                <form onSubmit={formik.handleSubmit}>
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <TextField
                                fullWidth
                                name='name'
                                label='Name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                variant='standard'
                            />
                        </div>
                        <div className='col-md-6 col-12'>
                            <TextField
                                fullWidth
                                name='email'
                                label='Email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                variant='standard'
                            />
                        </div>
                        <div className='col-12'>
                            <TextField
                                fullWidth
                                name='message'
                                label='Message'
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                error={formik.touched.message && Boolean(formik.errors.message)}
                                helperText={formik.touched.message && formik.errors.message}
                                variant='standard'
                            />
                        </div>
                        <div className='col-12 text-center'>
                            <div className='text-center mt-3'>
                                {submitted ?
                                    submitted === 'success' ? <span className='sbt-success'>Submitted Successfully</span> : <span className='sbt-failure'>Error, please try again later</span> : null}
                            </div>
                            <div className='text-center mt-4'>
                                <button type='button' type='submit' className='btn btn-contact-message'>SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    )
}

export default WorkWithUs