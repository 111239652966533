import React, { useEffect, useState } from 'react'
import logo from './img/logo.png'
import email from './img/email.svg'
import facebook from './img/facebook.svg'
import instagram from './img/instagram.svg'
import bz from './img/bzcon.png'
import { Link } from 'react-router-dom'
import axios from 'axios'

function Footer(props) {

  useEffect(() => {
    getLinks()
  }, [])

  const [links, setLinks] = useState([])

  const getLinks = () => {
    axios.get(`${props.api}/api/content/items/affiliates`, {
      headers: {
        "Content-Type": "application/json",
        'api-key': props.acctok,
        'Cache-Control': 'no-cache',
      }
    })
      .then(res => {
        setLinks(res.data)             
      })
      .catch(error => {
        console.log(error)
      })
  } 

  const allLinks = links.map(link => (
    <div key={link._id} className='link-wrapper'>
        <img src={`${props.api}/storage/uploads${link.image.path}`} className='link-pic' />
        <a className='link-href' href={link.link}>{link.content}</a>
    </div>
))

  return (
    <footer>
      <div className='text-center'>
        <img src={logo} className='footer-img' />
        <h1>WILLSWORLD PRODUCTION</h1>
        <div className='footer-link-wrapper-1'>
          <Link to='/reels'> <p>REELS &#x2022; </p> </Link>
          <Link to='/services'> <p>SERVICES &#x2022; </p> </Link>
          <Link to='/awards'> <p>AWARDS &#x2022; </p> </Link>
          <Link to='/news'> <p>NEWS</p> </Link>
        </div>
        <div className='footer-link-wrapper-2'>
          <Link to='/about'> <p>ABOUT &#x2022; </p> </Link>
          <Link to='/work-with-us'> <p>WORK WITH US</p> </Link>
        </div>
        <div className='footer-socials-menu-list' >
          <ul>
            <li> <a href='https://www.facebook.com/WillsworldProduction'> <img className='social-header-img' src={facebook} alt='facebook logo' /> </a> </li>
            <li> <a href='https://www.instagram.com/willsworldproduction/?hl=en'> <img className='social-header-img' src={instagram} alt='instagram logo' /> </a> </li>
            <li> <a href='mailto:willsworldproduction@hotmail.com'> <img className='social-header-img' src={email} alt='email logo' /> </a> </li>
          </ul>
        </div>
        <div className='affiliate-links mt-4'>
          {allLinks}
        </div>
      </div>
      <span className='bz-con'> <a href='https://buzzolution.com/'> <img className='bz-con-logo' src={bz} alt='buzzolution consultancy logo' /><p>Designed and developed by Buzzolution Consultancy</p></a></span>
    </footer>
  )
}

export default Footer