import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

function NewsPage(props) {

    useEffect(() => {
        getAwards()
    }, [])

    const [articles, setArticles] = useState([])

    const getAwards = () => {
        axios.get(`${props.api}/api/content/items/news`, {
            headers: {
                "Content-Type": "application/json",
                'api-key': props.acctok,
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                setArticles(res.data)               
            })
            .catch(error => {
                console.log(error)
            })
    }

    const showArticle = articles.map(article => (
        <div key={article._id} className='row'>
            <div className='col-md-5 col-12'>
                <img src={`${props.api}/storage/uploads${article.image.path}`} className='home-news-pic' />
            </div>
            <div className='col-md-7 col-12'>
                <h3 className='news-header'>{article.heading}</h3>
                <div className='news-content' dangerouslySetInnerHTML={{ __html: article.content }}></div>
            </div>
        </div>
    )).reverse().slice(0, 1)

    const allArticles = articles.map(article => (
        <div key={article._id} className='sinArticle mb-4'>
            <div className='col-12'>
                <Link to={`/news/${article.slug}`}>
                    <img src={`${props.api}/storage/uploads${article.image.path}`} className='home-news-pic' />
                </Link>
            </div>
            <div className='col-12'>
                <Link to={`/news/${article.slug}`}>
                    <h4 className='news-header mt-2'>{article.heading}</h4>
                    <p>{article.excerpt}</p>
                    <div className='home-read-more-btn text-center'>
                        <button type='button' type='submit' className='btn btn-contact-message'>READ MORE</button>
                    </div>
                </Link>
            </div>
        </div>
    ))

    return (
        <div className='newsPage container-fluid'>
            <h1 className='secHeading'>NEWS</h1>
            <div className='row'>
                <div className='col-md-9 col-12 news-article'>
                    {showArticle}
                </div>
                <div className='col-md-3 col-12 news-article'>
                    {allArticles}
                </div>
            </div>
        </div>
    )
}

export default NewsPage