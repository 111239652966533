import React from 'react'
import ReelHighlight from './ReelHighlight'

function ReelsPage(props) {
  return (
    <div className='continer-fluid reelsPage'>
        <h1 className='secHeading'>REELS</h1>
        <ReelHighlight api={props.api} acctok={props.acctok} />
    </div>
  )
}

export default ReelsPage